import { Disclosure } from '@headlessui/react'
import { useSession } from '@/hooks/useSession'
import { Avatar } from '@/components/core/Avatar'
import ChatSlider from '@/components/ai-assistant/ChatSlider'
import { ThreeDots } from 'react-loader-spinner'
import {
  MegaphoneIcon,
  FolderIcon,
  Squares2X2Icon,
  CogIcon,
  ClipboardDocumentIcon,
  ForwardIcon,
  ChartBarIcon,
  ArrowPathRoundedSquareIcon,
  UserGroupIcon,
  BoltIcon,
  LifebuoyIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  InboxIcon,
} from '@heroicons/react/24/outline'

import { WaIcon } from '@/components/core/icons/WaIcon'

import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import chatgptLogo from '@/images/logos/chatgpt.png'
import Image from 'next/image'
import { CustomLogo } from '@/components/Logo'
import api from '@/api'
import { CatchPromise } from '@/utils/catch-promise'
import { showToast } from '@/utils/toastify'
import { Tooltip } from 'react-tooltip'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import WhatsappCreditModal from '@/components/campaigns/WhatsappCreditModal'
import EmailCreditModal from '@/components/campaigns/EmailCreditModal'

const navigation = [
  {
    name: 'Get Started',
    icon: ForwardIcon,
    href: '/get-started',
  },
  {
    name: 'Dashboard',
    icon: Squares2X2Icon,
    current: false,
    href: '/dashboard',
  },
  {
    name: 'Lists',
    icon: UserGroupIcon,
    current: false,
    href: '/lists',
  },
  {
    name: 'Email',
    icon: EnvelopeIcon,
    current: false,
    href: '',
    children: [
      {
        name: 'Campaigns',
        icon: MegaphoneIcon,
        current: false,
        href: '/campaigns',
      },
      {
        name: 'Templates',
        icon: ClipboardDocumentIcon,
        current: false,
        href: '/templates',
      },
    ],
  },
  {
    name: 'WhatsApp',
    icon: WaIcon,
    current: false,
    isNew: true,
    href: '',
    children: [
      {
        name: 'Campaigns',
        icon: MegaphoneIcon,
        current: false,
        href: '/campaigns/whatsapp',
      },
      {
        name: 'Templates',
        icon: ClipboardDocumentIcon,
        current: false,
        href: '/templates/whatsapp',
      },
      {
        name: 'Inbox',
        icon: InboxIcon,
        current: false,
        isNew: true,
        href: '/whatsapp/inbox',
      }
    ],
  },
  // {
  //   name: 'Campaigns',
  //   icon: MegaphoneIcon,
  //   current: false,
  //   href: '/campaigns',
  // },
  // {
  //   name: 'Templates',
  //   icon: ClipboardDocumentIcon,
  //   current: false,
  //   href: '/templates',
  //   children: [
  //     {
  //       name: 'Email',
  //       icon: ClipboardDocumentIcon,
  //       current: false,
  //       href: '/templates/',
  //     },
  //     {
  //       name: 'WhatsApp',
  //       icon: ClipboardDocumentIcon,
  //       current: false,
  //       href: '/templates/whatsapp/',
  //     }
  //   ]
  // },
  {
    name: 'Automation',
    icon: ArrowPathRoundedSquareIcon,
    current: false,
    href: '/automations',
  },
  {
    name: 'Reports',
    icon: ChartBarIcon,
    current: false,
    href: '/reports',
  },
  {
    name: 'Settings',
    icon: CogIcon,
    current: false,
    href: '/settings/account-settings',
  },
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const openChat = function () {
  if (Beacon) {
    Beacon('open')
  }
}

const subscriberPerc = function (used, total) {
  if (used == null || total == null) return 0.0
  if (total === 0) return 0.0

  return (used / total) * 100.0
}

export function LeftNavigation ({ sidebarOpen, onSideBarClick }) {
  const [openSlider, setOpenSlider] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openEmailCreditModal, setOpenEmailCreditModal] = useState(false)

  const router = useRouter()
  const { signOut } = useSession()

  const isActive = (item) => {
    if (item.name == 'Email') {
      if (
        (router.pathname.startsWith('/templates') &&
          !router.pathname.includes('whatsapp')) ||
        (router.pathname.startsWith('/campaigns') &&
          !router.pathname.includes('whatsapp'))
      ) {
        return true
      } else {
        return false
      }
    } else if (item.name == 'WhatsApp') {
      if (
        (router.pathname.startsWith('/templates') &&
          router.pathname.includes('whatsapp')) ||
        (router.pathname.startsWith('/campaigns') &&
          router.pathname.includes('whatsapp'))
      ) {
        return true
      } else {
        return false
      }
    }

    const href = item.href

    if (router.asPath.startsWith('/templates') && href.includes('templates')) {
      if (router.asPath.includes('whatsapp') && href.includes('whatsapp'))
        return true
      if (!router.asPath.includes('whatsapp') && !href.includes('whatsapp'))
        return true
      return false
    }

    if (router.asPath.startsWith('/campaigns') && href.includes('campaigns')) {
      if (router.asPath.includes('whatsapp') && href.includes('whatsapp'))
        return true
      if (!router.asPath.includes('whatsapp') && !href.includes('whatsapp'))
        return true
      return false
    }

    return router.asPath.includes(href)
  }

  const [fetchingUsage, setFetchingUsage] = useState(false)
  const [usage, setUsage] = useState({
    subscriberQuotaUsed: 0,
    emailQuotaUsed: 0,
    emailQuota: 0,
    dailyEmailQuotaUsed: 0,
    subscriberQuota: 0,
  })

  const fetchCompanyUsage = async () => {
    setFetchingUsage(true)
    const [err, response] = await CatchPromise(api.company.usage())
    if (response) {
      setUsage(response.data)

    }
    setFetchingUsage(false)
  }

  const currentUser = useSelector((state) => state.sessions.currentUser)
  const subscriberUsedPerc = subscriberPerc(
    usage.subscriberQuotaUsed,
    usage.subscriberQuota
  )

  const emailUsedPerc = subscriberPerc(
    usage.emailQuotaUsed,
    usage.emailQuota
  )

  const dailyEmailUsedPerc = subscriberPerc(
    usage.dailyEmailQuotaUsed,
    100
  )

  function goToSelectPlan () {
    router.push('/settings/billing/your-plan')
  }

  useEffect(() => {
    fetchCompanyUsage()
  }, [])

  return (
    <>
      {openModal && (
        <WhatsappCreditModal setOpen={setOpenModal} open={openModal} />
      )}
      {openEmailCreditModal && (
        <EmailCreditModal
          setOpen={setOpenEmailCreditModal}
          open={openEmailCreditModal}
        />
      )}
      <ChatSlider setOpen={setOpenSlider} open={openSlider} />

      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={() => onSideBarClick(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => onSideBarClick(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto sm:mt-5">
                  <nav className="px-2">
                    <div className="mb-5 px-2 py-2">
                      <CustomLogo />
                    </div>
                    <div className="space-y-1">
                      {navigation.map((item) =>
                        !item.children ? (
                          <Link
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? 'text-gray-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 h-6 w-6 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        ) : (
                          <Disclosure
                            as="div"
                            key={item.name}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                    'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? 'text-gray-500'
                                        : 'text-gray-400 group-hover:text-gray-500',
                                      'mr-3 h-6 w-6 flex-shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  <span className="ml-auto">
                                    <svg
                                      className={classNames(
                                        open
                                          ? 'rotate-90 text-gray-400'
                                          : 'text-gray-300',
                                        'ml-2 h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                      )}
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M6 6L14 10L6 14V6Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1">
                                  {item.children.map((child) => (
                                    <Link
                                      key={child.name}
                                      href={child.href}
                                      className={classNames(
                                        child.current
                                          ? 'bg-gray-100 text-gray-900'
                                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5'
                                      )}
                                      aria-current={
                                        child.current ? 'page' : undefined
                                      }
                                    >
                                      <child.icon
                                        className={classNames(
                                          child.current
                                            ? 'text-gray-500'
                                            : 'text-gray-400 group-hover:text-gray-500',
                                          'mr-3 h-6 w-6 flex-shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                      {child.name}
                                    </Link>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                      )}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-50 lg:pt-5 lg:pb-4">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex h-0 flex-1 flex-col justify-between overflow-y-auto">
          {/* User account dropdown */}
          <div>
            <Menu as="div" className="relative px-3 text-left">
              <div>
                <Menu.Button className="group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-0">
                  <span className="flex w-full items-center justify-between">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      {currentUser.avatarUrl ? (
                        <Avatar imageUrl={currentUser.avatarUrl} />
                      ) : (
                        <Avatar email={currentUser.email} />
                      )}
                      <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-900">
                          {currentUser?.name}
                        </span>
                        <span className="truncate text-sm text-gray-500">
                          {currentUser?.email}
                        </span>
                      </span>
                    </span>
                    <ChevronUpDownIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/settings/account-settings"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          View profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/settings/account-settings/company"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Company Settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/settings/billing"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Billing
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/settings/integrations"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Integrations
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={openChat}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Chat with us
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          onClick={() => signOut('/login')}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Logout
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            {/* Navigation */}
            <nav className="mt-6 px-3">
              <div className="space-y-1">
                {navigation.map((item) =>
                  !item.children ? (
                    <Link
                      key={item.name}
                      href={item.href}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick()
                        }
                      }}
                      className={classNames(
                        isActive(item) && !item.disabledActiveState
                          ? 'bg-indigo-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          isActive(item) && !item.disabledActiveState
                            ? 'text-indigo-600'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 h-6 w-6 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              isActive(item)
                                ? 'text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                              'group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                isActive(item)
                                  ? 'text-indigo-600'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 h-6 w-6 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name} {item.isNew && <span className='text-xs text-red-500 ml-2 border rounded-full px-2 bg-white'>new</span>}
                            <span className="ml-auto">
                              {open ? (
                                <ChevronRightIcon className="h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400" />
                              )}
                            </span>
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-1">
                            {item.children.map((child) => (
                              <Link
                                key={child.name}
                                href={child.href}
                                className={classNames(
                                  isActive(child)
                                    ? 'bg-indigo-50 text-indigo-600'
                                    : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                                  'group flex items-center rounded-md px-2 py-2 pl-11 text-sm font-medium'
                                )}
                                aria-current={
                                  child.current ? 'page' : undefined
                                }
                              >
                                {/* <child.icon
                                  className={classNames(
                                    isActive(child.href)

                                      ? 'text-indigo-600'
                                      : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-3 h-6 w-6 flex-shrink-0'
                                  )}
                                  aria-hidden="true"
                                /> */}
                                {child.name} {child.isNew && <span className='text-xs text-red-500 ml-2 border rounded-full px-2 bg-white'>new</span>}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )
                )}

                <div
                  key={'Chat Support'}
                  onClick={() => {
                    if (Beacon) {
                      Beacon('open')
                    }
                  }}
                  className="group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                >
                  <LifebuoyIcon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Chat Support
                </div>
              </div>
            </nav>
          </div>
          <div>
            <nav className="mt-6 px-3">
              <div className="space-y-1">
                <Link
                  href="#"
                  className={classNames(
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900'
                  )}
                  onClick={() => setOpenSlider(true)}
                >
                  {/* <ChatBubbleOvalLeftEllipsisIcon
                    className={classNames(
                      'mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500'
                    )}
                    aria-hidden="true"
                  /> */}
                  <Image
                    className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    src={chatgptLogo}
                    alt="ChatGPT logo"
                  />
                  AI Assistant
                </Link>
              </div>
            </nav>

            <div className="px-5 text-left">

            {
              !fetchingUsage ?

              (<>
              <p className="mt-2 text-xs font-semibold text-gray-600">
                {currentUser.emailPlanType == 'credits'
                  ? 'Email Plan: Pay as you go'
                  : `${currentUser.company?.plan?.name} Plan`}

                {currentUser.company?.subscriptionStatus == 'trial' && (
                  <div className='mt-3'>
                    <span data-tooltip-id="trial-tooltip">
                      <span className="inline-flex cursor-pointer items-center rounded bg-yellow-100 px-2 py-0.5 text-xs font-medium uppercase text-yellow-800">
                        <EnvelopeIcon className="mr-3 h-4 w-4 text-yellow-500" />
                        {currentUser.company?.subscriptionStatus}{' '}
                        <InformationCircleIcon className="ml-1 h-4 w-4 text-yellow-700" />
                      </span>
                      <Tooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        className="tooltip z-[100] block"
                        id="trial-tooltip"
                      >
                        <div className="w-60 text-left text-xs">
                          In trial, you can only add upto 500 subscribers and
                          send 100 emails per day.
                          <br />
                          <br /> To remove these limits, you need to select a
                          plan.
                        </div>
                      </Tooltip>
                    </span>
                  </div>
                )}
                {currentUser.company?.subscriptionStatus == 'active' && (
                  <div>
                    <span className="mt-3 inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium uppercase text-green-800">
                      <EnvelopeIcon className="h-4 w-4 text-green-500 mr-3" />
                      {currentUser.company?.subscriptionStatus}
                    </span>
                  </div>
                )}

                {currentUser.company?.waSubscriptionStatus == 'active' && (
                  <div>
                    <span className=" mt-3 mb-3 inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium uppercase text-green-800">
                      <WaIcon className="mr-3 h-4 w-4 text-green-500" />
                      {currentUser.company?.waSubscriptionStatus}
                    </span>
                  </div>
                )}



                {(currentUser.emailPlanType != 'credits' && (currentUser.company?.subscriptionStatus == 'inactive' ||
                  currentUser.company?.subscriptionStatus == 'paused')) && (
                    <span
                      data-tooltip-id="inactive-tooltip"
                      className="inline-flex items-center mt-3 rounded bg-red-100 px-2 py-0.5 text-xs font-medium uppercase text-red-800"
                    >
                      <EnvelopeIcon className="mr-3 h-4 w-4" />
                      {currentUser.company?.subscriptionStatus}
                      <InformationCircleIcon className="ml-1 h-4 w-4 text-red-500" />

                      <Tooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        className="tooltip"
                        id="inactive-tooltip"
                      >
                        <span className="text-[14px]">
                          Your subscription is inactive. Please update your
                          payment details.
                          <br />
                          <Link
                            href="/settings/billing/your-plan"
                            className="text-sm font-semibold text-white"
                          >
                            Manage billing {'->'}
                          </Link>
                          <br />
                        </span>
                      </Tooltip>
                    </span>
                  )}

                {(currentUser.company?.waSubscriptionStatus == 'inactive' ||
                  currentUser.company?.waSubscriptionStatus == 'paused') && (
                    <span
                      data-tooltip-id="inactive-tooltip"
                      className="inline-flex items-center mb-2 mt-2 rounded bg-red-100 px-2 py-0.5 text-xs font-medium uppercase text-red-800"
                    >
                      <WaIcon className="mr-3 h-4 w-4" />
                      {currentUser.company?.waSubscriptionStatus}
                      <InformationCircleIcon className="ml-1 h-4 w-4 text-red-500" />

                      <Tooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        className="tooltip"
                        id="inactive-tooltip"
                      >
                        <span className="text-[14px]">
                          Your Whatsapp is inactive. Please update your
                          payment details.
                          <br />
                          <Link
                            href="/settings/billing/your-plan"
                            className="text-sm font-semibold text-white"
                          >
                            Manage billing {'->'}
                          </Link>
                          <br />
                        </span>
                      </Tooltip>
                    </span>
                  )}
              </p>
              {currentUser.company?.subscriptionStatus == 'trial' ? (
                <div className="pb-2">
                  <button
                    // onClick={endTrial}
                    onClick={goToSelectPlan}
                    className="text-xs font-semibold text-indigo-600"
                  >
                    {loading ? (
                      <span>
                        <ThreeDots
                          height="24"
                          width="30"
                          radius="10"
                          color="#4f46e5"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </span>
                    ) : (
                      <div data-tooltip-id="end-trial-tooltip">
                        <div>Select plan and end trial {'->'}</div>
                        <Tooltip
                          place="top"
                          type="dark"
                          effect="solid"
                          className="tooltip"
                          id="end-trial-tooltip"
                        >
                          <div className="w-60 text-left text-xs">
                            In trial, you can only add upto 500 subscribers and
                            send 100 emails per day.
                            <br />
                            <br /> To remove these limits, you need to select a
                            plan.
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </button>
                </div>
              ) : null}

              {(currentUser.company?.plan?.regular && currentUser.whatsappEnabled) ? (
                <>
                  <p className="mt-5 text-xs text-gray-600">
                    {/* {currentUser.company?.currency == 'USD' ? '$' : '₹'} */}
                    {'₹'}
                    <span className="font-semibold">
                      {currentUser.wc ? currentUser.wc.toLocaleString() : 0}
                    </span>{' '}
                    whatsapp balance
                  </p>
                  <div className="text-md mb-5">
                    <Link
                      href="#"
                      onClick={() => setOpenModal(true)}
                      className="text-sm font-semibold text-indigo-600"
                    >
                      Recharge {'->'}
                    </Link>
                  </div>
                </>
              ) : null}

              {currentUser.emailPlanType == 'credits' ? (
                <>
                  <p className="mt-5 text-xs text-gray-600">
                    <span className="font-semibold">
                      {currentUser.emailCredits
                        ? currentUser.emailCredits.toLocaleString()
                        : 0}
                    </span>{' '}
                    email credits remaining
                  </p>
                  <div className="text-md mb-5">
                    <Link
                      href="#"
                      onClick={() => setOpenEmailCreditModal(true)}
                      className="text-sm font-semibold text-indigo-600"
                    >
                      Recharge {'->'}
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  <p className="mt-2 text-xs text-gray-600">
                    Used{' '}
                    <span className="font-semibold">
                      {usage.subscriberQuotaUsed
                        ? usage.subscriberQuotaUsed.toLocaleString()
                        : 0}
                    </span>{' '}
                    of{' '}
                    <span className="font-semibold">
                      {usage.subscriberQuota
                        ? usage.subscriberQuota.toLocaleString()
                        : ''}
                    </span>{' '}
                    subscribers
                  </p>
                  <div className="mt-2 overflow-hidden rounded-full bg-gray-200">
                    <div
                      className={classNames(
                        subscriberUsedPerc <= 65.0 ? 'bg-green-500' : '',
                        subscriberUsedPerc <= 85.0 && subscriberUsedPerc > 65.0
                          ? 'bg-yellow-500'
                          : '',
                        subscriberUsedPerc > 85.0 ? 'bg-red-500' : '',
                        'h-1 rounded-full'
                      )}
                      style={{ width: `${subscriberUsedPerc}%` }}
                    />
                  </div>
                  {currentUser.company?.plan?.regular &&
                    (currentUser.company?.plan?.freePlan ||
                      currentUser.company?.subscriptionStatus == 'trial') ? (
                    <>
                      <p className="mt-2 text-xs text-gray-600">
                        Used{' '}
                        <span className="font-semibold">
                          {usage.dailyEmailQuotaUsed
                            ? usage.dailyEmailQuotaUsed.toLocaleString()
                            : 0}
                        </span>{' '}
                        of <span className="font-semibold">100</span> daily
                        emails limit
                      </p>
                      <div className="mt-2 overflow-hidden rounded-full bg-gray-200">
                        <div
                          className={classNames(
                            dailyEmailUsedPerc <= 65.0 ? 'bg-green-500' : '',
                            dailyEmailUsedPerc <= 85.0 &&
                              dailyEmailUsedPerc > 65.0
                              ? 'bg-yellow-500'
                              : '',
                            dailyEmailUsedPerc > 85.0 ? 'bg-red-500' : '',
                            'h-1 rounded-full'
                          )}
                          style={{ width: `${dailyEmailUsedPerc}%` }}
                        />
                      </div>
                    </>
                  ) : null}
                  {currentUser.company?.plan?.regular &&
                    !(
                      currentUser.company?.plan?.freePlan ||
                      currentUser.company?.subscriptionStatus == 'trial'
                    ) ? (
                    <>
                      <p className="mt-2 text-xs text-gray-600">
                        Used{' '}
                        <span className="font-semibold">
                          {usage.emailQuotaUsed
                            ? usage.emailQuotaUsed.toLocaleString()
                            : 0}
                        </span>{' '}
                        of{' '}
                        <span className="font-semibold">
                          {usage.emailQuota
                            ? usage.emailQuota.toLocaleString()
                            : ''}
                        </span>{' '}
                        emails
                      </p>
                      <div className="mt-2 overflow-hidden rounded-full bg-gray-200">
                        <div
                          className={classNames(
                            emailUsedPerc <= 65.0 ? 'bg-green-500' : '',
                            emailUsedPerc <= 85.0 && emailUsedPerc > 65.0
                              ? 'bg-yellow-500'
                              : '',
                            emailUsedPerc > 85.0 ? 'bg-red-500' : '',
                            'h-1 rounded-full'
                          )}
                          style={{ width: `${emailUsedPerc}%` }}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              )}
              {currentUser.company?.subscriptionStatus != 'trial' && (
                <>
                  <div className="text-md mt-2">
                    <Link
                      href="/settings/billing/your-plan"
                      className="text-sm font-semibold text-indigo-600"
                    >
                      Manage billing {'->'}
                    </Link>
                  </div>
                </>
              )}
              </>) : (
                <>
                  <p className="mt-2 text-xs font-semibold text-gray-600">
                    Fetching usage...
                  </p>
                </>
              )}

              <div className="mt-5 pt-4">
                <Link href="/">
                  <CustomLogo />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
