import Image from "next/image"
import logoChq from '@/images/logos/campaignhq-logo.svg'
export function Logo(props) {
  return (
    <Image src={logoChq} width="140" alt="Logo" unoptimized className="mx-auto" />
  )
}

export function CustomLogo(props) {
  return (
    <Image src={logoChq} width="100" alt="Logo" unoptimized className={props.className} />
  )
}
